<template>
  <div>
    <div class="card border-0">
      <form @submit.prevent="" class="w-100">
        <div class="row" v-if="(user.id === selectedAuditRecord.created_by_id || isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.oe-details.edit')) || (isOwnVessel('vesselAuditRecord') && user.is_vessel === true)">
          <div class="col-12 text-right">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancel">Cancel
              </button>
              <button class="e-btn e-btn-blue ml-2" type="submit" @click="submit" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" :disabled="hasChanges === false">Save</button>
            </template>
            <button class="e-btn e-btn-green ml-2" type="button" v-else @click="editDetails">Edit Details</button>

          </div>
        </div>

        <template v-if="toEdit">
          <div class="mb-3">
            <table class="w-100">
              <tr v-if="isOwnVessel('vesselAuditRecord') && user.is_vessel === true">
                <td style="width: 25%">Category</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']]?.objective_evidences[$attrs['active-oe-index']]?.category?.name  }}
                </td>
              </tr>
              <tr v-else>
                <td style="width: 25%">Category</td>
                <td>
                  <select class="form-control form-control-sm my-2" v-model="oe.category_id">
                    <option :value="null">-- Select Category --</option>
                    <template v-for="category in oECategories">
                      <option :value="category.id">{{ category.name }} {{ category.description ? '- ' + category.description : '' }}</option>
                    </template>
                  </select>
                </td>
              </tr>
              <tr v-if="isOwnVessel('vesselAuditRecord') && user.is_vessel === true">
                <td style="width: 25%">Ref to SMS/Regulator</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']]?.objective_evidences[$attrs['active-oe-index']]?.reference }}
                </td>
              </tr>
              <tr v-else>
                <td style="width: 25%">Ref to SMS/Regulator</td>
                <td>
                  <input type="text" class="form-control form-control-sm w-100" v-model="oe.reference">
                </td>
              </tr>
              <tr v-if="isOwnVessel('vesselAuditRecord') && user.is_vessel === true">
                <td style="width: 25%">Objective Evidence Description</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']]?.objective_evidences[$attrs['active-oe-index']]?.description | stripHtmlTags }}
                </td>
              </tr>
              <tr v-else>
                <td style="width: 25%">Objective Evidence Description</td>
                <td>
                  <vue-editor v-model="oe.description" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
                  </vue-editor>
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Attachments</td>
                <td>
                  <table class="table table-sm mb-0">
                    <tr style="border: none; width: 50%">
                    </tr>
                  </table>
                  <table class="table table-sm table-bordered">
                    <thead class="text-center">
                    <tr>
                      <th class="align-middle" style="width: 50%">BEFORE</th>
                      <th class="align-middle">AFTER</th>
                    </tr>
                    </thead>
                    <tr>
                      <td>
                        <div style="max-height: 60vh; overflow-y: auto">
                          <template v-if="isOwnVessel('vesselAuditRecord') && user.is_vessel === true">
                            <template v-for="(image, index) in attachments.before.images">
                              <template v-if="image.image_url.startsWith('http') === false">
                                <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"
                                     @dblclick="viewImage(index, attachments.before.images, {
                         'path'      : 'image_url',
                         'caption'   : 'name'
                       })">
                                  <div class="p-2">
                                    <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">
                                      <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.image_url + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <template v-else>
                                <div style="float: left;" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                                  <div class="p-2">
                                    <div data-v-16e2512b="" data-v-71fad98a="" class="card p-4">
                                      <div :style="{ minWidth: '50%', width: 'auto', height: '230.5px' }" class="background-contain">
                                        <div class="centered-element-in-div">
                                          <app-loading
                                            :spinner-only="true"
                                            :height="'100px'"
                                            :width="'100px'"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </template>

                          </template>
                          <attachments-handler
                            v-else
                            :key="'update-before-images-' + toEdit"
                            ref="update-before-images-attachments-handler"
                            :accepted-file-types="['image/*']"
                            :current-attachments="beforeImagesForPond"
                            :hide-file-names="true"
                            :max-height="500"
                            :deleting-permission="!(isOwnVessel('vesselAuditRecord') && user.is_vessel === true)"
                            @attachment-has-changes="setChangeValue"
                            :changes_id="'before'"
                          />
                        </div>
                      </td>
                      <td>
                        <div style="max-height: 60vh; overflow-y: auto">
                            <attachments-handler
                              :key="'update-after-images-' + toEdit"
                              ref="update-after-images-attachments-handler"
                              :accepted-file-types="['image/*']"
                              :current-attachments="afterImagesForPond"
                              :hide-file-names="true"
                              :max-height="500"
                              @attachment-has-changes="setChangeValue"
                              :changes_id="'after'"
                            />
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr v-if="isOwnVessel('vesselAuditRecord') && user.is_vessel === true">
                <td style="width: 25%">Corrective Action Request</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']]?.objective_evidences[$attrs['active-oe-index']]?.ca_request | stripHtmlTags }}
                </td>
              </tr>
              <tr v-else>
                <td style="width: 25%">Corrective Action Request</td>
                <td>
                  <vue-editor v-model="oe.ca_request" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
                  </vue-editor>
                </td>
              </tr>
              <tr v-if="isOwnVessel('vesselAuditRecord') && user.is_vessel === true">
                <td style="width: 25%">Rectified on the spot</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']]?.objective_evidences[$attrs['active-oe-index']]?.rectified === 1 ? 'YES' : 'NO' }}
                </td>
              </tr>
              <tr v-else>
                <td style="width: 25%">Rectified on the spot</td>
                <td>
                  <div class="form-group row no-gutters mb-1">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input checkbox-input" type="checkbox" value="" id="training_need" :checked="oe.rectified === 1" @click="oe.rectified = setOneOrZero(oe.rectified)">
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td style="width: 25%">Rectified Date</td>
                <td>
                  <input type="date" :min="minimumDate"  class="form-control form-control-sm" v-model="oe.rectified_date">
                </td>
              </tr>

              <tr>
                <td style="width: 25%">Rectification Comment</td>
                <td>
                  <vue-editor v-model="oe.rectified_comment" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
                  </vue-editor>
                </td>
              </tr>


            </table>
          </div>
        </template>
        <template v-else>
          <div class="mb-3">
            <table class="w-100">
              <tr>
                <td style="width: 25%">Category</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']]?.objective_evidences[$attrs['active-oe-index']]?.category?.name  }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Ref to SMS/Regulator</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']]?.objective_evidences[$attrs['active-oe-index']]?.reference }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Objective Evidence Description</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']]?.objective_evidences[$attrs['active-oe-index']]?.description | stripHtmlTags }}
                </td>
              </tr>

              <tr>
                <td style="width: 25%">Attachments</td>
                <td>
                  <table class="table table-sm table-bordered">
                    <thead class="text-center">
                    <tr>
                      <th class="align-middle" style="width: 50%">BEFORE</th>
                      <th class="align-middle">AFTER</th>
                    </tr>
                    </thead>
                    <tr>
                      <td>
                        <div style="max-height: 60vh; overflow-y: auto">
                          <template v-if="attachments.before.images.length > 0">
                            <template v-for="(image, index) in attachments.before.images">
                              <template v-if="image.image_url.startsWith('http') === false">
                                <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"
                                     @dblclick="viewImage(index, attachments.before.images, {
                         'path'      : 'image_url',
                         'caption'   : 'name'
                       })">
                                  <div class="p-2">
                                    <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">
                                      <span v-if="toEdit" class="cursor-pointer close-selected-image" aria-hidden="true" @click.stop="deleteImage('before', index)">&times;</span>
                                      <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.image_url + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <template v-else>
                                <div style="float: left;" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                                  <div class="p-2">
                                    <div data-v-16e2512b="" data-v-71fad98a="" class="card p-4">
                                      <div :style="{ minWidth: '50%', width: 'auto', height: '230.5px' }" class="background-contain">
                                        <div class="centered-element-in-div">
                                          <app-loading
                                            :spinner-only="true"
                                            :height="'100px'"
                                            :width="'100px'"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </template>
                          </template>
                          <template v-else>
                            <div data-v-71fad98a="" class="col-md-12 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                              <div class="p-2">
                                <div data-v-16e2512b="" data-v-71fad98a="" class="p-4">
                                  <div :style="{ minWidth: '100%%', width: 'auto', height: '230.5px' }" class="background-contain">
                                    <div class="centered-element-in-div" style="width: max-content">
                                      No Image Attached
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </td>
                      <td>
                        <div style="max-height: 60vh; overflow-y: auto">
                          <template v-if="attachments.after.images.length > 0">
                            <template v-for="(image, index) in attachments.after.images">
                              <template v-if="image.image_url.startsWith('http') === false">
                                <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"
                                     @dblclick="viewImage(index, attachments.after.images, {
                         'path'      : 'image_url',
                         'caption'   : 'name'
                       })">
                                  <div class="p-2">
                                    <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">
                                      <span v-if="toEdit" class="cursor-pointer close-selected-image" aria-hidden="true" @click.stop="deleteImage('after', index)">&times;</span>
                                      <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.image_url + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <template v-else>
                                <div style="float: left;" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                                  <div class="p-2">
                                    <div data-v-16e2512b="" data-v-71fad98a="" class="card p-4">
                                      <div :style="{ minWidth: '50%', width: 'auto', height: '230.5px' }" class="background-contain">
                                        <div class="centered-element-in-div">
                                          <app-loading
                                            :spinner-only="true"
                                            :height="'100px'"
                                            :width="'100px'"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </template>
                          </template>
                          <template v-else>
                            <div data-v-71fad98a="" class="col-md-12 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                              <div class="p-2">
                                <div data-v-16e2512b="" data-v-71fad98a="" class="p-4">
                                  <div :style="{ minWidth: '100%%', width: 'auto', height: '230.5px' }" class="background-contain">
                                    <div class="centered-element-in-div" style="width: max-content">
                                      No Image Attached
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Corrective Action Request</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']]?.objective_evidences[$attrs['active-oe-index']]?.ca_request | stripHtmlTags }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Rectified on the spot</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']]?.objective_evidences[$attrs['active-oe-index']]?.rectified === 1 ? 'YES' : 'NO' }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Rectified Date</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']].objective_evidences[$attrs['active-oe-index']]?.rectified_date.human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Rectification Comment</td>
                <td>
                  {{ ncrMainDetailsData[$attrs['active-ncr-index']].objective_evidences[$attrs['active-oe-index']]?.rectified_comment | stripHtmlTags }}
                </td>
              </tr>

            </table>
          </div>
        </template>
      </form>
    </div>
    <!--    FOR IMAGE SLIDER OF ATTACHMENTS   -->
    <image-slider
      :selected-image-index="selectedImageIndex"
      :images="sliderImages"
      :visible="showSlider"
      @hideSlider="resetSlider()"
    >
    </image-slider>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import RootCauseSelector from "@/components/modules/ia/vessel/nonConformity/details/RootCauseSelector.vue";
import {DataService} from "@/services/DataService";
import ObjectiveEvidenceMixin from "@/mixins/ObjectiveEvidenceMixin";
import NonConformityMixin from "@/mixins/NonConformityMixin";
import dateMixin from "@/mixins/DateMixin";

import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";
import AppLoading from "@/components/elements/AppLoading.vue";
import AttachmentsHandler from "@/components/common/AttachmentsHandler.vue";

export default {
  name : 'OEMainDetails',
  components: {AttachmentsHandler, AppLoading, RootCauseSelector, ImageSlider},
  mixins : [ObjectiveEvidenceMixin, NonConformityMixin, dateMixin, ImageSliderMixin],
  data() {
    return {
      toEdit : false
    }
  },
  methods: {
    ...mapActions([
      'updateObjectiveEvidence',
    ]),

    async editDetails() {
      await this.setDetails();
      this.toEdit = true;
    },
    async submit() {
      const beforeImagesIsProcessing = this.$refs["update-before-images-attachments-handler"].isProcessingFiles();
      const beforeImagesHasError = this.$refs["update-before-images-attachments-handler"].filesHasError();
      const afterImagesIsProcessing = this.$refs["update-after-images-attachments-handler"].isProcessingFiles();
      const afterImagesHasError = this.$refs["update-after-images-attachments-handler"].filesHasError();

      if (beforeImagesHasError || afterImagesHasError) {
        return;
      }

      if (beforeImagesIsProcessing || afterImagesIsProcessing) {
        return AlertService.warningAlert('Please wait for the images to finish processing', 'ATTACHMENTS PROCESSING');
      }

      swal.fire({...this.swalOptions, ...{title: 'SAVING CHANGES, PLEASE WAIT...'}});
      swal.showLoading();

      let errorUploadingResponse = {
        result : true,
        error_message  : null
      };

      const newBeforeFiles = this.$refs["update-before-images-attachments-handler"].getValidFilesUniqueIds();
      const newAfterFiles = this.$refs["update-after-images-attachments-handler"].getValidFilesUniqueIds();
      const beforeFilesToDelete = this.$refs["update-before-images-attachments-handler"].getFilesToRemove().map(item => item.id);
      const afterFilesToDelete = this.$refs["update-after-images-attachments-handler"].getFilesToRemove().map(item => item.id);

      if (newBeforeFiles.length > 0) {
        let beforeFormData = new FormData();
        newBeforeFiles.forEach(file_unique_id => {
          beforeFormData.append('file_unique_ids[]', file_unique_id);
        });

        const updateImageResponse = await this.updateOEImageAttachment({
          id : this.objectiveEvidenceId,
          type : 'before',
          data : beforeFormData
        });
        if (updateImageResponse.result === false) {
          errorUploadingResponse.result = false;
          errorUploadingResponse.error_message = updateImageResponse.message;
          return AlertService.errorAlert(updateImageResponse.message, 'UPLOADING ATTACHMENT IN OBJECTIVE EVIDENCE');
        }
      }

      if (newAfterFiles.length > 0) {
        let afterFormData = new FormData();
        newAfterFiles.forEach(file_unique_id => {
          afterFormData.append('file_unique_ids[]', file_unique_id);
        });
        const updateImageResponse = await this.updateOEImageAttachment({
          id : this.objectiveEvidenceId,
          type : 'after',
          data : afterFormData
        });
        if (updateImageResponse.result === false) {
          errorUploadingResponse.result = false;
          errorUploadingResponse.error_message = updateImageResponse.message;
          return AlertService.errorAlert(updateImageResponse.message, 'UPLOADING ATTACHMENT IN OBJECTIVE EVIDENCE');
        }
      }

      if (beforeFilesToDelete.length > 0) {
        await this.deleteOeAttachments({
          oe_id : this.$route.params.oe_id,
          ids : beforeFilesToDelete,
          type : 'before'
        });
      }

      if (afterFilesToDelete.length > 0) {
        await this.deleteOeAttachments({
          oe_id : this.$route.params.oe_id,
          ids : afterFilesToDelete,
          type : 'after'
        });
      }

      const updateResponse = await this.updateObjectiveEvidence(this.oe);
      if (updateResponse.result === false) {
        return AlertService.errorAlert(updateResponse.message, 'UPDATING OF NCR/OBS DETAIL');
      }
      await this.getUpdatedNonConformity();
      this.resetImageIdsHolders();
      this.resetChangesHandler();
      this.toEdit = false;
      swal.close();
      await AlertService.successAlert('Updated successfully', 'UPDATING OF NCR/OBS DETAIL');
      this.oe = this.setOeDetailInitialValues();
      await this.initializeAttachments();
      await this.convertBeforeAndAfterImagesToBase64();

      this.$refs["update-before-images-attachments-handler"].resetValues();
      this.$refs["update-after-images-attachments-handler"].resetValues();

    },
    async cancel() {
      if (this.hasChanges === false) {
        this.toEdit = false;
        return;
      }
      if (await AlertService.cancelAlert()) {
        this.toEdit = false;
        // this.resetImageIdsHolders();
        this.resetChangesHandler();
        this.oe = this.setOeDetailInitialValues();
        await this.initializeAttachments();
        await this.convertBeforeAndAfterImagesToBase64();

      }
    },
    async setDetails() {
      const currentDetails = this.ncrMainDetailsData[this.$attrs['active-ncr-index']].objective_evidences[this.$attrs['active-oe-index']]

      this.oe = {
        id : currentDetails.id,
        category_id : currentDetails.category_id,
        description : currentDetails.description,
        ca_request : currentDetails.ca_request,
        reference : currentDetails.reference,
        rectified : currentDetails.rectified,
        rectified_date : currentDetails.rectified_date['date-picker'],
        rectified_comment : currentDetails.rectified_comment,
      }
    },
    async convertBeforeAndAfterImagesToBase64() {
      let beforeImages = this.attachments.before.images;
      let afterImages = this.attachments.after.images;

      beforeImages = await this.convertImagesToBase645(beforeImages);
      afterImages = await this.convertImagesToBase645(afterImages);
    },
    setChangeValue(changeId, value) {
      this.imagesChanges[changeId] = value;
    }
  },
  async created() {
    this.activeNcrIndex = this.$attrs['active-ncr-index'];
    this.activeOeIndex = this.$attrs['active-oe-index'];
    await this.initializeAttachments();
    await this.convertBeforeAndAfterImagesToBase64();
  },
  computed : {
    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      const details = this.ncrMainDetailsData[this.$attrs['active-ncr-index']].objective_evidences[this.$attrs['active-oe-index']]
      const originalData = {
        category_id : details.category_id ?? null,
        description : details.description,
        ca_request : details.ca_request,
        reference : details.reference,
        rectified : details.rectified,
        rectified_date : details.rectified_date['date-picker'],
        rectified_comment : details.rectified_comment,
      }

      const editedData = {
        category_id : this.oe.category_id,
        description : this.oe.description,
        ca_request : this.oe.ca_request,
        reference : this.oe.reference,
        rectified : this.oe.rectified,
        rectified_date : this.oe.rectified_date,
        rectified_comment : this.oe.rectified_comment
      }

      return this.imagesChanges.before === true || this.imagesChanges.after === true || DataService.checkIfChanged(originalData, editedData);

    },
    beforeImagesForPond() {
      return this.attachments.before.images.map(image => {
        return {
          source : image.path,
          options : {
            type : 'local',
            metadata : {
              id : image.id,
              thumbnail : image.thumbnail,
              file_size : image.file_size,
              created_at : image.created_at
            }
          }
        }
      })
    },
    afterImagesForPond() {
      return this.attachments.after.images.map(image => {
        return {
          source : image.path,
          options : {
            type : 'local',
            metadata : {
              id : image.id,
              thumbnail : image.thumbnail,
              file_size : image.file_size,
              created_at : image.created_at
            }
          }
        }
      })
    }

  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges() === true) {
        next();
      }
    } else {
      next();
    }
  }
}
</script>

<style scoped>
table td {
  padding: 2px;
}

.close-selected-image {
  color: red;
  font-weight: bold;
  font-size: 40px;
  position: absolute;
  top: -11px;
  right: 0;
  opacity: 0.7;
}

.close-selected-image:hover {
  opacity: 1;
}

#ia-attachment-card:hover {
  background-color: #dae8f4;
  transition: 0.5s;
}
</style>
